import TournamentAwardsService from '../services/TournamentAwardsService';

class TournamentAwardsController {
    async getCurrentAwards(tournamentId){
        try {
            const tournamentAwardsService = new TournamentAwardsService()    
            let awards = await tournamentAwardsService.getCurrentAwards(tournamentId)
            return awards[0]
        } catch(e) { console.log(e) }
    }
}

export default TournamentAwardsController