import React, { Component, Suspense } from 'react';
import { Switch, BrowserRouter, Route, Redirect } from 'react-router-dom';
import { ROUTES, CURRENT_YEAR, WEEKS_PER_ROUND, ROUNDS_PER_SEASON, PLAYOFFS_ROUNDS } from './constants/index'
import TournamentsController from './controllers/TournamentsController';
import TournamentFinalsController from './controllers/TournamentFinalsController';
import TournamentAwardsController from './controllers/TournamentAwardsController';

import Layout from './views/components/layout';
import SplashScreen from './views/components/splashScreen';
const Home = React.lazy(() => import('./views/screens/Home'));
const Standings = React.lazy(() => import('./views/screens/Standings'));
const StrikersAndFairplay = React.lazy(() => import('./views/screens/StrikersAndFairplay'));
const Fixture = React.lazy(() => import('./views/screens/Fixture'));
const Dashboard = React.lazy(() => import('./views/screens/Dashboard'));
const TournamentCreator = React.lazy(() => import('./views/screens/TournamentCreator'));

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			secondaryTheme: false,
			tournament: {},
			current_week: null,
			selectedTournament: 'tournament', // tournament | cup
			selectedFinal: '', // gold - silver - promotion
		}
	}

	async componentDidMount() {
		let tournaments = await this.fetchTournaments()
		if (tournaments && tournaments.length > 0) {
			let currentTournament = tournaments[tournaments.length - 1];
			let finals = await this.fetchFinals(currentTournament.id)
			currentTournament.finals = {}
			finals.forEach(final => {
				currentTournament.finals[final.type] = {...final}
			})
			let tournamentIsOver = currentTournament.current_week > WEEKS_PER_ROUND * ROUNDS_PER_SEASON + PLAYOFFS_ROUNDS
			let regularSeasonIsOver = currentTournament.current_week > WEEKS_PER_ROUND * ROUNDS_PER_SEASON
			if (tournamentIsOver) {
				currentTournament.awards = await this.fetchAwards(currentTournament.id)
			}
			this.setState({ 
				tournament: currentTournament, 
				current_week: currentTournament.current_week,
				selectedFinal: (regularSeasonIsOver && !tournamentIsOver) ? 'gold' : '',
			})
		}
	}

	async fetchTournaments() {
		const tournamentsController = new TournamentsController()
		try {
			let tournaments = await tournamentsController.getCurrentTournament(CURRENT_YEAR)
			return tournaments
		} catch (e) { console.log(e) }
	}

	async fetchFinals(tournamentId) {
		const finalsController = new TournamentFinalsController()
		try {
			let finals = await finalsController.getCurrentFinals(tournamentId)
			return finals
		} catch (e) { console.log(e) }
	}

	async fetchAwards(tournamentId) {
        const awardsController = new TournamentAwardsController()
        try {
            let awardsData = await awardsController.getCurrentAwards(tournamentId)
            return awardsData

        } catch(e){ console.log(e)}
    }

	changeTheme(isSecondary=false) {
		this.setState({ secondaryTheme: isSecondary })
	}

	handleTournamentSelection(value, isSecondary = false) {
		if (this.state.selectedTournament === value && this.state.current_week > WEEKS_PER_ROUND * ROUNDS_PER_SEASON) return null
		this.changeTheme(isSecondary)
		this.setState({ selectedtournament: value, selectedFinal: '' })
  }

	handleFinalSelection(value, tournamentId) {
		this.setState({selectedFinal: value})
	}

	render() {
		const { secondaryTheme, tournament, current_week, selectedFinal, selectedTournament } = this.state
		return (
			<>
				<BrowserRouter>
				{/* TODO: Delete when site is live: */}
				<Route path={'/'} component={() => <SplashScreen withComingSoon />}/>
					{/* <Suspense 
						fallback={
							<SplashScreen />
						}
					>
						<Layout
							hidden={window.location.pathname.includes('admin') && !tournament} // Change this to length === 0
							tournament={tournament && tournament}
							secondaryTheme={secondaryTheme}
							selectedFinal={selectedFinal}
							selectedTournament={selectedTournament}
							handleFinalSelection={this.handleFinalSelection.bind(this)}
							handleTournamentSelection={this.handleTournamentSelection.bind(this)}
							isTournamentOver={current_week > WEEKS_PER_ROUND * ROUNDS_PER_SEASON + PLAYOFFS_ROUNDS}
						>
							<Switch>
								<Route 
									exact path={ROUTES.Homepage} 
									component={() => 
										<Home 
											secondaryTheme={secondaryTheme} 
											current_week={current_week} 
											selectedFinal={selectedFinal} 
											finals={tournament.finals}
											awards={tournament.awards}
										/>
									}
								/>
								<Route 
									exact path={ROUTES.Positions} 
									component={() => 
										<Standings 
											secondaryTheme={secondaryTheme} 
											current_week={current_week}
											tournamentId={tournament.id}
											desc_team={tournament.awards && tournament.awards.descTeamId}
											promo_desc_team={tournament.awards && tournament.awards.promoDescTeamId}
										/>
									} 
								/>
								<Route 
									exact path={ROUTES.StrikersAndFairplay} 
									component={() => 
										<StrikersAndFairplay 
											secondaryTheme={secondaryTheme}
										/>
									} 
								/>
								<Route 
									exact path={ROUTES.Fixture} 
									component={() => 
										<Fixture 
											secondaryTheme={secondaryTheme} 
											current_week={current_week}
										/>
									} 
								/>
								<Route 
									exact path={ROUTES.Dashboard} 
									component={() => <Dashboard tournaments={tournament}/>} 
								/>
								<Route 
									exact path={`${ROUTES.Dashboard}${ROUTES.TournamentCreator}`} 
									component={() => <TournamentCreator /> } 
								/>
								<Redirect from="*" to={ROUTES.Homepage} />
							</Switch>
						</Layout>
					</Suspense> */}
				</BrowserRouter>
			</>
		);
	}
}

export default App;
