import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './scss/main.module.scss';
import './fonts/GalanoGrotesqueAlt-Regular.otf'
import './fonts/GalanoGrotesqueAlt-Bold.otf'
import './fonts/GalanoGrotesqueAlt-Black.otf'
import './fonts/GalanoGrotesqueAlt-Medium.otf'
import './fonts/GalanoGrotesqueAlt-SemiBold.otf'
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
