import secrets from '../config/secrets'
const axios = require('axios')

export default class TournamentFinalsService {
	async getCurrentFinals(tournamentId){
		try {
			let response = await axios.get(`${secrets.API_URL}${secrets.ROUTE_BASE_PATH}/tournamentFinals`, { 
				params: {
					tournamentId
				},
			})
			return response.data
		} catch(e) { console.log(e) }
	}
}