export const CURRENT_YEAR = 2020;
export const ROUTES = {
    Homepage: '/',
    Positions: '/posiciones',
    Fixture: '/fixture',
    StrikersAndFairplay: '/goleadores-fairplay',
    Dashboard: '/admin/panel',
    TournamentCreator: '/crear-torneo'
}

// tournament mode
export const TEAMS_QTY = 10;
export const WEEKS_PER_ROUND = 9;
export const ROUNDS_PER_SEASON = 2;
export const PLAYOFFS_ROUNDS = 2;
export const MIN_PLAYER_QTY = 2;
export const MAX_PLAYER_QTY = 5;

export const TOURNAMENT_TYPES = [
    {
      id: 'league',
      name: 'Liga'
    },
    {
      id: 'league_and_finals',
      name: 'Liga + Finales'
    },
    {
      id: 'groups_and_playoffs',
      name: 'Grupos + Playoffs'
    }
];

export const PLAYER_TYPE = 'PLAYER'
export const TEAM_TYPE = 'TEAM'

export const TOURNAMENT_AWARDS = [ // IN ORDER OF APPEARANCE IN AWARDS SCREEN
  {
    id: "champion_team",
    type: TEAM_TYPE
  },
  {
    id: "mvp",
    type: PLAYER_TYPE
  },
  {
    id: "best_striker",
    type: PLAYER_TYPE
  },
  {
    id: "best_gk",
    type: PLAYER_TYPE
  },
  {
    id: "fairplay_team",
    type: TEAM_TYPE
  },
  {
    id: "asc_team",
    type: TEAM_TYPE
  },
  {
    id: "desc_team",
    type: TEAM_TYPE
  },
  {
    id: "promo_asc_team",
    type: TEAM_TYPE
  },
  {
    id: "promo_desc_team",
    type: TEAM_TYPE
  },
]

// tournament final positions
export const POSITIONS_PER_FINAL = ['champion_team', 'finalist_team', 'third_team', 'fourth_team'] 