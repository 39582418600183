import React from 'react';
import PropTypes from 'prop-types';
import style from './style.module.scss';


const SplashScreen = ({
	withFootball = true,
	withComingSoon = false,
	...otherProps
}) => (
	<div 
		className={style.splashContainer}
		{...otherProps}
	>
		<div className={style.overlay}/>
		<div className={style.half}>
			<div className={style.logo}/>
		</div>
		<div className={style.half}>
			{
				withFootball &&
					<div className={style.ball}/>
			}
			{
				withComingSoon &&
					<div className={style.comingSoon}>PRÓXIMAMENTE</div>
			}
		</div>
	</div>
)

SplashScreen.propTypes = {
	withFootball: PropTypes.bool
}

export default SplashScreen;