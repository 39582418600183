import secrets from '../config/secrets'
const axios = require('axios')

export default class TournamentAwardsService {
	async getCurrentAwards(tournamentId){
		try {
			let response = await axios.get(`${secrets.API_URL}${secrets.ROUTE_BASE_PATH}/tournamentAwards`, { 
				params: {
					tournamentId
				},
			})
			return response.data
		} catch(e) { console.log(e) }
	}
}