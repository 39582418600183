export const TEXTS = {
  homepage: {
    main_title: 'PRÓXIMA FECHA',
    main_secondary_title: 'FECHA',
    field_label: 'CANCHA',
    semifinal_title: 'SEMI-<br>FINALES',
    promotion_title: 'PROMO<br>CIÓN',
    final_title: "LA FINAL",
    finals_versus_label: "VS",
    finals_advantage_label: "VD",
    finals_3rd_label: "3ER PUESTO",
    direct_promotion_label: "Promoción directa",
    finals_semifinal_1_time_text: "15",
    finals_semifinal_1_local_label: "EQUIPO #1",
    finals_semifinal_1_visitor_label: "EQUIPO #4",
    finals_semifinal_2_time_text: "17",
    finals_semifinal_2_local_label: "EQUIPO #2",
    finals_semifinal_2_visitor_label: "EQUIPO #3",
    direct_promotion_asc_placeholder: "CAMPEÓN",
    finals_promotion_time_text: "15",
    finals_promotion_local_label: "EQUIPO #9",
    finals_promotion_visitor_label: "EQUIPO #12",
    direct_promotion_asc_placeholder_label: "TORNEO PRIMERA B",
    direct_promotion_desc_placeholder: "EQUIPO #10",
    tournament_awards_champion_title: "CAMPEÓN",
    tournament_awards_mvp_label: "MVP",
    tournament_awards_best_striker_label: "Goleador",
    tournament_awards_best_gk_label: "Valla menos vencida",
    tournament_awards_fairplay_team_label: "Fairplay",
  },
  
  standings: {
    main_title: 'POSICIONES',
    primary_label: 'Normal',
    secondary_label: 'Sin División',
    final_label: 'Final',
    final_primary_label: 'Regular',
  
    table_team_label: 'EQUIPO',
    table_points_label: 'PTS',
    table_played_label: 'J',
    table_results_label: 'G-E-P',
    table_goals_label: 'GOLES',
    table_wins_label: "G",
    table_draws_label: "E",
    table_losses_label: "P",
    table_goals_for_label: "GF",
    table_goals_against_label: "GC",
    table_goals_diff_label: "DIF",
    table_streak_label: "ÚLTIMOS PARTIDOS",
  },

  fixture: {
    main_title: 'FIXTURE',
    first_label: '1ª ronda',
    second_label: '2ª ronda',
    suspended_label: 'SUSP.',
    penalty_description: 'ganó por penales',
  },

  strikers_and_fairplay: {
    strikers_label: 'Goleadores',
    fairplay_label: 'Fairplay',  
  },

  strikers: {
    table_team_label: 'EQUIPO',
    table_player_label: 'JUGADOR',
    table_goals_label: 'GOLES',
    table_filter_default: 'Todos los equipos',
    empty_goals_label: 'No hay goleadores',
  },

  fairplay: {
    table_suspended_label: 'SUSPENDIDOS',
    table_suspended_pending_label: 'PEND.',
    table_suspended_total_label: 'TOT.',
    table_bookings_label: 'AMONESTADOS',
    empty_suspended_label: 'No hay suspendidos',
    empty_bookings_label: 'No hay amonestados',
  },

  sidebar: {
    report_a_bug: {
      label: "Reportar un error",
      email: "die.a.ferrari@gmail.com",
      subject: `Error en CNO Fútbol`,
      body: `-%20Tipo%20de%20error%20(Dato%20err%C3%B3neo%20%2F%20Mal%20funcionamiento)%3A%0D%0A%0D%0A-%20Dispositivo%20y%20navegador%20(Ej%3A%20Celular%20samsung%20s20%20-%20Chrome)%3A%0D%0A%0D%0A-%20Encontr%C3%A9%20el%20error%20en%20(Ej%3A%20En%20la%20secci%C3%B3n%20de%20resultados%2C%20en%20la%20fecha%208)%3A%0D%0A%0D%0A-%20(Mal%20Funcionamiento)%20Me%20encontr%C3%A9%20con%20el%20error%20haciendo%20(Ej%3A%20En%20la%20tabla%20de%20posiciones%20hice%20click%20en%20la%20tab%20de%20%22Sin%20Divisi%C3%B3n%22)%3A%0D%0A%0D%0A`
      // body creator: mailtolink.me
        // - Tipo de error (Dato erróneo / Mal funcionamiento):

        // - Dispositivo y navegador (Ej: Celular samsung s20 - Chrome):

        // - Encontré el error en (Ej: En la sección de resultados, en la fecha 8):

        // - (Mal Funcionamiento) Me encontré con el error haciendo (Ej: En la tabla de posiciones hice click en la tab de "Sin División"):

    },
    regulation_label: "Descargar reglamento",
    contact_label: "Contacto",
    contact_email: 'die.a.ferrari@gmail.com', 
    signature: 'Desarrollada por Diego Ferrari',
    gold_label: "Copa de Oro",
    silver_label: "Copa de Plata",
    promotion_label: "Promoción",
  },

  desktopNav: {
    home: "Inicio",
    positions: "Posiciones",
    fixture: "Fixture",
    strikers_and_fairplay: "Goleadores / Fairplay"
  },

  dashboard: {
    home_empty_button_label: "Crear torneo",
    
    tournament_creator: {
      progress_label_step_1: 'Datos del torneo',
      progress_label_step_2: 'Equipos',
      progress_label_step_3: 'Resumen',

      tournament_info_title: 'Datos del torneo',
      tournament_name_label: "Nombre",
      tournament_name_placeholder: "Torneo de Primera A",
      tournament_type_label: 'Tipo',
      tournament_type_placeholder: 'Seleccionar tipo',
      tournament_team_amount_label: 'Cantidad de equipos',
      tournament_team_amount_placeholder: '10',
      tournament_groups_amount_label: 'Cantidad de grupos',
      tournament_groups_amount_placeholder: '5',
      tournament_format_selection_title: 'Formato ',
      tournament_format_selection_title_league: 'de liga ',
      tournament_format_selection_title_groups: 'de grupos ',
      tournament_format_type_label_league: 'Liga',
      tournament_format_type_label_league_and_finals: 'Liga + Finales',
      tournament_format_type_label_groups_and_playoffs: 'Grupos + Playoffs',
      tournament_format_label_single_round: 'Ida',
      tournament_format_label_double_round: 'Ida y vuelta',
      tournament_format_label_points_divided: 'Con división de puntos',
      tournament_main_button_label: 'Continuar',

      teams_info_title: 'Equipo ',
      teams_name_label: 'Nombre del equipo',
      teams_name_placeholder: 'Los Pibes',
      teams_color_label: 'Colores principales del equipo',
      teams_players_title: 'Plantel',
      teams_players_number_label: 'Nro.',
      teams_players_number_placeholder: '10',
      teams_players_lastname_label: 'Apellido',
      teams_players_lastname_placeholder: 'Pérez',
      teams_players_name_label: 'Nombre',
      teams_players_name_placeholder: 'Juan',

      teams_info_buttons_label_primary: 'Continuar',
      teams_info_buttons_label_secondary: 'Volver',

      tournament_summary_title: "Resumen del torneo",
      tournament_summary_label_primary: 'Crear torneo',
      tournament_summary_label_secondary: 'Volver',
      tournament_summary_label_teams: 'equipos'
    },
  }
}